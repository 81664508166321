/*=============== RENEW FUNC ===============*/
async function renewToken() {
    try {
        const response = await axios.post('/api/v1/renew-api-key', {}, {timeout: 5000});
        if (response.status !== 200) {
            console.error("Erreur lors du renouvellement de l'api key:", response.data);
        }
    } catch (error) {
        console.error('Erreur lors du renouvellement du token:', error);
    }
}

async function checkTokenExpiration() {
    if (typeof cookieStore !== 'undefined') {
        const cookie = await cookieStore.get('api-token');
        const expirationTime = cookie ? cookie.expires : 0;

        if (!expirationTime) return false;

        const currentTime = Date.now();
        const timeLeft = expirationTime - currentTime;
        return timeLeft < 60000;
    } else {
        const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
            const [key, value] = cookie.split('=');
            acc[key] = value;
            return acc;
        }, {});
        return !cookies['api-token'];
    }
}

/*=============== REQUEST FUNC ===============*/
async function makePostRequest(path, data = {}, auto_renew_api_key = false) {
    try {
        if (auto_renew_api_key) {
            const isTokenExpiringSoon = await checkTokenExpiration();
            if (isTokenExpiringSoon) {
                await renewToken();
            }
        }
        let result = await axios.post(path, data, {timeout: 5000, headers: {'Content-Type': 'application/json'}})
        return {"response": result.data, "code": result.status}
    } catch(error){
        console.error(error);
        let result
        if (error.response === undefined) {
            result = {"response": error.data, "code": error.status};
        } else if (error.code === 'ECONNABORTED') {
            result = {"response": {status: 408, status_code: "Timeout", detail: "timeout"}, "code": 408};
        } else {
            result = {"response": error.response.data, "code": error.response.status};
        }
        return result
    }
}


async function makeGetRequest(path, auto_renew_api_key = false) {
    try {
        if (auto_renew_api_key) {
            const isTokenExpiringSoon = await checkTokenExpiration();
            if (isTokenExpiringSoon) {
                await renewToken();
            }
        }
        let result = await axios.get(path, {timeout: 5000, headers: {'Content-Type': 'application/json'}})
        return {"response": result.data, "code": result.status}
    } catch(error){
        let result
        if (error.response === undefined) {
            result = {"response": error.data, "code": error.status};
        } else if (error.code === 'ECONNABORTED') {
            result = {"response": {status: 408, status_code: "Timeout", detail: "timeout"}, "code": 408};
        } else {
            result = {"response": error.response.data, "code": error.response.status};
        }
        return result
    }
}

async function makeGetRequestImage(path, data = {}, auto_renew_api_key = false) {
    try {
        if (auto_renew_api_key) {
            const isTokenExpiringSoon = await checkTokenExpiration();
            if (isTokenExpiringSoon) {
                await renewToken();
            }
        }

        let result = await axios.get(path, {timeout: 5000, responseType: 'blob'});
        return {"response": result.data, "code": result.status, "headers": result.headers}
    } catch(error){
        console.error(error);
        let result
        if (error.response === undefined) {
            result = {"response": error.data, "code": error.status};
        } else if (error.code === 'ECONNABORTED') {
            result = {"response": {status: 408, status_code: "Timeout", detail: "timeout"}, "code": 408};
        } else {
            result = {"response": error.response.data, "code": error.response.status};
        }
        return result
    }
}